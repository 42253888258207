import "./style.scss";

import MuiButton from "@mui/material/Button";
import cx from "classnames";
import React from "react";

import LoadingDots from "../LoadingDots";

const Button = ({
  className,
  type,
  startIcon,
  endIcon,
  onClick,
  disabled,
  disableRipple,
  children,
  isLoading,
  // ...
  variant,
  // ...
  width,
  height,
  fontSize,
  lineHeight,
  fontWeight,
  color,
  margin,
}) => (
  <MuiButton
    className={cx("Button", { [className]: className, [variant]: variant })}
    type={type}
    startIcon={startIcon}
    endIcon={endIcon}
    onClick={isLoading ? null : onClick}
    disableRipple={disableRipple}
    disabled={disabled}
    style={{ width: width, height: height, margin: margin }}
  >
    <span
      className="Button__inner"
      style={{
        color: color,
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontWeight: fontWeight,
        visibility: isLoading ? "hidden" : "visible",
      }}
    >
      {children}
    </span>
    {isLoading && <LoadingDots />}
  </MuiButton>
);

export default Button;
