import { DateTime } from "luxon";

import { sGetAllItems } from "./storage";

export const getDataToSave = (data) => {
  let ens_et_mod_ins = data?.programme?.ens_et_mod_ins?.filter((ens, idx) => {
    const now = DateTime.local().toUTC().toISO();
    const start = ens.date_ouverture_inscritption;
    const end = ens.date_fermeture_inscritption;
    // ...
    if (!ens.inscription_en_ligne || !start || !end) return false;
    return start <= now && end > now;
    // return true;
  });
  // ...
  return {
    ...data,
    programme: { ...(data?.programme || {}), ens_et_mod_ins },
    voeux: data?.voeux || []
  };
};

export const validateEnsVoeuxList = (ensList, voeuxList) => {
  return ensList.map((e) => {
    const minB = e.modalite_inscription_ens?.nbr_options_a_choisir_min;
    const minO = parseInt(minB) <= 1 ? minB : 0;
    const ensVoeux = voeuxList.filter((v) => e.ens.id === v.enseignement.id);
    // ...
    return ensVoeux.length >= minO;
  });
};

export const canAddSelection = ({ meta, opt, currOpts }) => {
  if (currOpts.length + 1 > meta.maxO) return false;
  if (typeof meta.maxHW !== "number") return true;
  // ...
  const optHW = opt.poid_horaire || 0;
  const optsHW = currOpts.reduce((t, o) => (o.poid_horaire || 0) + t, 0);
  // ...
  if (optsHW + optHW > meta.maxHW) return false;
  // ...
  return true;
};

export const formatStoredVoeux = (raw, baseOpts) => {
  const voeux = raw?.data?.[0]?.attributes?.voeux || [];
  return voeux?.map((v) =>
    v.choix.map((c) => {
      let id = null;
      // ...
      baseOpts.forEach((opt) => {
        if (opt.libelle === c.libelle) id = opt.id;
      });
      // ..
      return id;
    })
  );
};

export const formatSaveVoeux = (baseEns, baseOpts, options) => {
  const b = sGetAllItems();
  const userId = b.USER?.id;
  // ...
  return {
    etudiant: userId,
    enseignement: baseEns.ens.id,
    // ...
    libelle: baseEns.ens.libelle,
    voeux: options.map((b, bIdx) => {
      let str = b.map((oId) => {
        let opt = {};
        // ...
        baseOpts.forEach((bo) => {
          if (bo.id === oId) opt = bo;
        });
        // ...
        return opt.libelle.split(" ")?.[0];
      })
      // console.log(str);
      // ...
      return {
        libelle: `Voeux ${bIdx + 1}: ( ${str.join(' - ')} )`,
        choix: b.map((oId) => {
          let opt = {};
          // ...
          baseOpts.forEach((bo) => {
            if (bo.id === oId) opt = bo;
          });
          // ...
          return { libelle: opt.libelle, option: opt.id };
        })
      };
    })
  };
};

export const getIntersectOptions = (base, reduced) => {
  if (!reduced?.length) return base;
  // ...
  const reducedIds = reduced.map((opt) => opt.id);
  return base.filter((opt) => reducedIds.indexOf(opt.id) > -1);
};

export const getSaveChecks = (mo, opts) => {
  let out = true;
  // ...
  opts.forEach((v) => {
    if (v.length < mo) out = false;
  });
  // ...
  return out;
};

export const validateEnsData = (ens) => {
  let out = { err: false, data: "" };
  // ...
  const data = ens?.ens || {};
  const options = data.options || [];

  //# Validate Ens Options
  options.forEach((o) => {
    if (!o.option) out = { err: true, data: "OPTION" };
  });

  // ...
  return out;
};
