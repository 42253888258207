import TextField from "@mui/material/TextField";
import cx from "classnames";
import React from "react";

const EntryText = ({
  className = "",
  type = "text",
  name,
  label,
  placeholder = "",
  value,
  onChange,
  onBlur,
  error,
  customError,
  // ...
  readOnly,
  multiline,
  rows,
}) => {
  let helperText = error ? `${label} is required` : "";
  if (error && customError) helperText = error;
  // ...
  return (
    <div className={cx("Entry EntryText", { [className]: className })}>
      <TextField
        variant="outlined"
        // ...
        type={type}
        name={name}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        multiline={multiline}
        rows={rows}
        fullWidth
        error={!!error}
        helperText={helperText}
        InputLabelProps={{ shrink: true }}
        InputProps={{ readOnly }}
        disabled={readOnly}
      />
    </div>
  );
};

export default EntryText;
