import "./style.scss";

import React from "react";
import { NavLink } from "react-router-dom";

const NavLinkI = ({ to = "/", children, style = {} }) => {
  return (
    <NavLink className="NavLink" to={to} style={{ ...style }}>
      {children}
    </NavLink>
  );
};

export default NavLinkI;
