import React from "react";

const Card = ({ id, data }) => {
  // ...
  return (
    <div
      // ref={(node) => drag(drop(node))}
      className="OptionItem DragItem"
      // style={{ opacity: isDragging ? 0 : 1 }}
    >
      <div className="__inner">
        <div className="__content">
          <div className="__top">
            <span className="__label">{data?.libelle}</span>
            {data?.poid_horaire && <span className="__ph">{data.poid_horaire} heures</span>}
          </div>

          {data?.thematique && <div className="__bottom">{data.thematique}</div>}
        </div>
      </div>
    </div>
  );
};

export default Card;
