import "./style.scss";

import cx from "classnames";
import React, { useEffect, useRef } from "react";

import Logo from "../../components/Logo";
import NavbarUser from "../../components/NavbarUser";
import NavLink from "../../components/NavLink";

const MainLayout = ({ className, children, currTab = 0, user }) => {
  const contentRef = useRef();
  // ...
  useEffect(() => {
    contentRef.current.scrollTo(0, 0);
  }, [currTab]);
  // ...
  return (
    <div
      className={cx("MainLayout", { [className]: !!className })}
      key="main-layout"
    >
      <div className="MainLayout__navbar">
        <div className="MainLayout__navbar__inner">
          <div className="MainLayout__navbar__logo">
            <NavLink to="/dashboard">
              <Logo isDash />
            </NavLink>
          </div>
          <div className="MainLayout__navbar__user">
            <NavbarUser user={user} />
          </div>
        </div>
      </div>

      <div className="MainLayout__main">
        <div ref={contentRef} className="MainLayout__content">
          {children}
        </div>
      </div>
      <p style={{textAlign: "center"}}>Pour tout problème contacter cet adresse email: <a href="mailto:sos-inscription@paris-lavillette.archi.fr">sos-inscription@paris-lavillette.archi.fr</a></p>
    </div>
  );
};

export default MainLayout;
