import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import None from "../None";
import routes from "./routes";

const Dashboard = ({ user }) => {
  // ...
  return (
    <>
      {!user && <Navigate to="/authentication" replace={true} />}

      <Routes>
        {routes().map((route) => (
          <Route key={route.id} path={route.path} element={<route.Component {...route.props} user={user} />} />
        ))}

        <Route path="*" element={<None user={user} />} />
      </Routes>
    </>
  );
};

export default Dashboard;
