import axios from "axios";
import { sEntities, sGetItem } from "../libs/storage";

var qs = require("qs");

//# Inst
const HTTP = axios.create({
  baseURL: "https://backoffice.paris-lavillette.archi.fr/api",
  timeout: 9999999999999
});

// Inst. Requests
HTTP.interceptors.request.use(
  async (config) => {
    config.headers.Accept = "application/json";
    config.headers["Content-Type"] = "application/json";
    // ...
    const jwt = sGetItem(sEntities.JWT);
    if (jwt) config.headers.Authorization = `Bearer ${jwt}`;
    else config.headers.Authorization = null;
    // ...
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

//# Auth APIs
export const USER_AUTH = async (req) => {
  try {
    const res = await HTTP.post("/auth/local", req);
    const data = res.data;
    // ...
    if (data?.error && !data?.jwt) return { error: true, reason: "NO_USER", data };
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: null
    };
  }
};

//# Programme
export const GET_PROGRAMME = async () => {
  try {
    const res = await HTTP.get(`/programmes`);
    const data = res.data;
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: null
    };
  }
};

export const GET_VOEUX = async (userId, ensId) => {
  try {
    const res = await HTTP.get(`/temp-voeuxes?populate=deep,5&filters[etudiant][id]$eq]=${userId}&filters[enseignement][id]$eq]=${ensId}`);
    const data = res.data;
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: null
    };
  }
};
// ....
export const POST_VOEUX = async (req) => {
  try {
    const res = await HTTP.post("/temp-voeuxes", req);
    const data = res.data;
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: err
    };
  }
};
// ...
export const PUT_VOEUX = async (id, req) => {
  try {
    const res = await HTTP.put(`/temp-voeuxes/${id}`, req);
    const data = res.data;
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: err
    };
  }
};
// ...
export const DELETE_VOEUX = async (id) => {
  try {
    const res = await HTTP.delete(`/temp-voeuxes/${id}`);
    const data = res.data;
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: null
    };
  }
};

//# Validate All Voeux
export const VALIDATE_ALL = async () => {
  try {
    const res = await HTTP.get(`/programmes/validateVoeux`);
    const data = res.data;
    // ...
    return { error: false, reason: "", data };
  } catch (err) {
    return {
      error: true,
      reason: err?.code || "ERROR",
      data: null
    };
  }
};
