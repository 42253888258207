import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

const EntryPassword = ({
  name,
  label,
  placeholder = "",
  error,
  readOnly,
  // ...
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const onShowPassword = () => {
    setShowPassword(() => !showPassword);
  };
  // ...
  return (
    <div className="Entry EntryPassword">
      <TextField
        {...props}
        variant="outlined"
        type={showPassword ? "text" : "password"}
        name={name}
        label={label}
        placeholder={placeholder}
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={!!error}
        readOnly={readOnly}
        helperText={error}
        InputProps={{
          readOnly,
          endAdornment: (
            <p className="__show-hide" onClick={onShowPassword}>
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </p>
          ),
        }}
      />
    </div>
  );
};

export default EntryPassword;
