import "./style.scss";

import cx from "classnames";
import React from "react";

const Container = ({ variant, height, children, style }) => {
  return (
    <div
      className={cx("Container", { [variant]: !!variant })}
      style={{ height, ...style }}
    >
      <div className="Container__wrapper">{children}</div>
    </div>
  );
};

export default Container;
