import "./style.scss";

import cx from "classnames";
import React from "react";

const Logo = ({ isDash }) => (
  <div className={cx("Logo", { __dash: isDash })}>
    <div className="__tmp">
      <span className="__ensa">ensa</span>
      <span className="__paris">de Paris-La Villette</span>
    </div>
  </div>
);

export default Logo;
