import Checkbox from "@mui/material/Checkbox";
import cx from "classnames";
import React from "react";

const Item = ({ data, checked, disabled, cvIdx, onCheckHandler }) => {
  // ...
  return (
    <div className={cx("OptionItem", { __selected: true })}>
      <div
        className="__inner"
        onClick={() => {
          if (disabled && checked) return;
          onCheckHandler(data, !checked);
        }}>
        <div className="__checkbox">
          <Checkbox checked={checked} inputProps={{ "aria-label": "controlled" }} disabled={disabled} />
        </div>
        <div className="__content">
          <div className="__top">
            <span className="__label">{data.libelle}</span>
            {data.poid_horaire && <span className="__ph">{data.poid_horaire} heures</span>}
          </div>
          {!!cvIdx && <div className="__bottom">Voeux {cvIdx}</div>}
        </div>
      </div>
    </div>
  );
};

export default Item;
