import "./style.scss";

import React from "react";
import { sClearAllItems } from "../../libs/storage";

const NavbarUser = ({ user }) => {
  
  // ...
  return (
    <div className="NavbarUser">
      <div className="NavbarUser__user">
        <div
          className="__logout"
          onClick={() => {
            sClearAllItems();
          }}>
          Se déconnecter
        </div>
        <div className="__avatar">
          <span>{user?.username?.[0]?.toUpperCase() || "E"}</span>
        </div>
      </div>
    </div>
  );
};

export default NavbarUser;
