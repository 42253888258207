import ListAltIcon from "@mui/icons-material/ListAlt";
import cx from "classnames";
import React from "react";

import Card from "./CardN";

const DragList = ({ idx, cards }) => {
  const icards = cards[idx] || [];
  const isEmpty = icards?.length === 0;
  const oneCard = icards?.length === 1;
  // ...
  return (
    <div className={cx("DragList", { __empty: isEmpty || oneCard })}>
      {icards.map((card) => (
        <Card key={card.id} id={`${card.id}`} data={card} />
      ))}

      {isEmpty && (
        <div className="DragListEmpty">
          <div className="__inner">
            <ListAltIcon />
            <span>
              Sélectionnez les options désirées
              <br /> dans votre ordre de préférence
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default DragList;
