import "./style.scss";

import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import _ from "underscore";

import Button from "../../components/Button";
import { EntryPassword, EntryText } from "../../components/Entry";
import AuthLayout from "../../layout/AuthLayout";
// ...
import { validationSchema } from "./data";
const iniValues = validationSchema.cast();

import { sEntities, sSetAllItems } from "../../libs/storage";
import { getDataToSave } from "../../libs/helpers";
import { USER_AUTH } from "../../services";

const SignIn = ({ user, ...props }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [errors, setErrors] = useState({});
  // ...
  const onValidate = async () => {
    return props.validateForm(props.values);
  };
  // ...
  const onTryLogin = async () => {
    setIsLoading(true);
    // ...
    const validate = await onValidate();
    if (!_.isEmpty(validate)) {
      setIsLoading(false);
      return setErrors({ ...validate });
    }
    // ...
    const { error, reason, data } = await USER_AUTH(props.values);
    setIsLoading(false);
    // ...
    if (error) {
      if (reason === "NO_USER") setLoginError("Aucun étudiant trouvé, réessayez");
      else setLoginError("Une erreur s'est produite réessayez");
    } else {
      const dataToSave = getDataToSave(data);
      // ...
      sSetAllItems({
        [sEntities.JWT]: dataToSave?.jwt || "",
        [sEntities.USER]: JSON.stringify(dataToSave?.user || {})
      });
    }
  };
  // ...
  useEffect(() => {
    setLoginError("");
    setErrors({});
  }, [props.values]);
  // ...
  return (
    <>
      {user && <Navigate to="/dashboard/enseignements" replace={true} />}

      <AuthLayout
        width="450px"
        onKeyPressHandler={() => {
          onTryLogin();
        }}>
        <div className="AuthSignIn">
          <div className="AuthLayout__content__header">
            <p className="AuthLayout__content__title">Interface de connexion pour les étudiants</p>

            {!!loginError && <p className="AuthLayout__content__desc __error">{loginError}</p>}
          </div>

          <div className="AuthLayout__content__form AuthSignIn__form">
            <EntryText
              name="identifier"
              label="Identifiant LDAP"
              value={props.values.identifier}
              onChange={props.handleChange}
              error={errors.identifier}
            />

            <EntryPassword name="password" label="Mot de passe" value={props.values.password} onChange={props.handleChange} error={errors.password} />

            <Button fontSize="16px" isLoading={isLoading} onClick={onTryLogin}>
              Sign in
            </Button>
          </div>
        </div>
      </AuthLayout>
    </>
  );
};

export default withFormik({
  mapPropsToValues: () => ({ ...iniValues }),
  validationSchema
})(SignIn);
