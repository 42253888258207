const storage = window.localStorage;

export const sEntities = {
  JWT: "__jwt",
  USER: "__user",
  PROG: "__program",
  CURSUS: "__cursus"
};

const eventBroadcast = () => {
  window.dispatchEvent(new Event("storage"));
};

export const sSetItem = (key, value) => {
  storage.setItem(key, value);
  eventBroadcast();
};

export const sGetItem = (key) => {
  return storage.getItem(key);
};

export const sGetAllItems = () => {
  let out = {};
  // ...
  Object.keys(sEntities).forEach((k) => {
    let value = storage.getItem(sEntities[k]);
    if (k === "JWT") return (out[k] = value);
    out[k] = JSON.parse(value || "{}");
  });
  // ...
  return out;
};

export const sSetAllItems = (items) => {
  Object.keys(items).forEach((k) => {
    storage.setItem(k, items[k]);
  });
  // ...
  eventBroadcast();
};

export const sClearAllItems = () => {
  sSetAllItems({
    [sEntities.JWT]: "",
    [sEntities.USER]: "",
    [sEntities.PROG]: "",
    [sEntities.CURSUS]: ""
  });
};
