import CssBaseline from "@mui/material/CssBaseline";
import Grow from "@mui/material/Grow";
import { SnackbarProvider } from "notistack";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Snackbar from "./components/Snackbar";
import { sEntities, sGetItem } from "./libs/storage";
// ...
import Auth from "./papers/Auth";
import Dashboard from "./papers/Dashboard";
import None from "./papers/None";

const App = () => {
  const [isInit, setIsInit] = useState(false);
  const [user, setUser] = useState(null);
  // ...
  const onInitApp = () => {
    // const jwt = sGetItem(sEntities.JWT);
    const user = sGetItem(sEntities.USER);
    // ...
    setUser(user ? JSON.parse(user) : null);
    setIsInit(true);
  };
  // ...
  window.addEventListener("storage", () => {
    onInitApp();
  });
  useEffect(() => {
    onInitApp();
  }, []);

  // ...
  if (!isInit) return null;
  // ...
  return (
    <SnackbarProvider
      dense
      maxSnack={1}
      TransitionComponent={Grow}
      anchorOrigin={{ horizontal: "center", vertical: "top" }}
      content={(key, message, ...props) => (
        <Snackbar id={key} message={message} {...props} />
      )}
    >
      <CssBaseline />
      <Router>
        <Routes>
          <Route path="/authentication" element={<Auth user={user} />} />
          <Route path="/dashboard/*" element={<Dashboard user={user} />} />

          <Route path="*" element={<None user={user} />} />
        </Routes>
      </Router>
    </SnackbarProvider>
  );
};

export default App;
