import InfoIcon from "@mui/icons-material/Info";
import React from "react";

const ModIns = ({ meta = {} }) => {
  // ...
  return (
    <div className="DashEnseignementsMOD">
      <div className="__header">Modalité d'inscription</div>
      <div className="__inner">
        <div className="__group">
          <div className="__item">
            <span>
              <InfoIcon />
            </span>
            <span>Minimum de voeux à choisir:</span>
            <span>{meta.minV}</span>
          </div>
          <div className="__item">
            <span>
              <InfoIcon />
            </span>
            <span>Maximum de voeux à choisir:</span>
            <span>{meta.maxV}</span>
          </div>
        </div>

        <div className="__group">
          <div className="__item">
            <span>
              <InfoIcon />
            </span>
            <span>Minimum d'options à choisir par voeux:</span>
            <span>{meta.minO}</span>
          </div>
          <div className="__item">
            <span>
              <InfoIcon />
            </span>
            <span>Maximum d'options à choisir par voeux:</span>
            <span>{meta.maxO}</span>
          </div>
        </div>

        <div className="__group">
          <div className="__item">
            <span>
              <InfoIcon />
            </span>
            <span>Maximum d'heures à avoir pour un voeux:</span>
            <span>{meta.maxHW || "N/A"}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModIns;
