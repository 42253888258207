import Dashboard from "./sections";
// ...
import Enseignements from "./sections/Enseignements";
import EnseignementsManage from "./sections/Enseignements/Manage";

//# Routes
const routes = () => {
  // ...
  return [
    {
      id: "Dashboard",
      path: "enseignements/:id",
      Component: EnseignementsManage,
      props: {},
    },
    {
      id: "Dashboard",
      path: "enseignements",
      Component: Dashboard,
      props: {
        stepIndex: 1,
        Paper: Enseignements,
      },
    },
  ];
};

export default routes;
