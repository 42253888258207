import "./style.scss";
import React, { useEffect, useState } from "react";
import Button from "../../../../components/Button";
import Container from "../../../../components/Container";
import EnsItem from "../../../../components/EnsItem";
import { VALIDATE_ALL, GET_PROGRAMME } from "../../../../services";
import { useSnackbar } from "notistack";
import { sEntities, sSetAllItems } from "../../../../libs/storage";
import CircularProgress from "@mui/material/CircularProgress";
import {
  validateEnsVoeuxList,
  getDataToSave
} from "../../../../libs/helpers";

const Enseignements = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [isValEnsLoading, setIsValEnsLoading] = useState(true);
  const [isValAllLoading, setIsValAllLoading] = useState(false);
  const [data, setData] = useState({ cursus: {}, programme: {}, voeux: [] });
  const [ensListVal, setEnsListVal] = useState([]);
  const canValidateAll = ensListVal.reduce((x, y) => x && y, true);
  // ...
  const ensList = data?.programme?.ens_et_mod_ins || [];
  const voeuxList = data?.voeux || [];
  console.log(ensList);
  // ...
  const onValidateVoeux = async () => {
    setEnsListVal(validateEnsVoeuxList(ensList, voeuxList));
    setIsLoading(false);
    setIsValEnsLoading(false);
  };
  // ...
  const onGetProgramme = async () => {
    const { data, error } = await GET_PROGRAMME();
    if (error) return enqueueSnackbar({ msg: "Une erreur s'est produite lors de la récupération des données", isError: true });
    const dataToSave = getDataToSave(data);

    // # Refresh Stored Data
    setData((s) => ({ ...s, cursus: dataToSave?.cursus || {}, programme: dataToSave?.programme || {}, voeux: dataToSave?.voeux || [] }));
    sSetAllItems({
      [sEntities.CURSUS]: JSON.stringify(dataToSave?.cursus || {}),
      [sEntities.PROG]: JSON.stringify(dataToSave?.programme || {})
    });
  };
  // ...
  const onValidateAll = async () => {
    setIsValAllLoading(true);
    const { error } = await VALIDATE_ALL();
    if (error) enqueueSnackbar({ msg: "Une erreur s'est produite lors de l'envoie de l'email de confirmation", isError: true });
    else enqueueSnackbar({ msg: "L'email de confirmation a été envoyé", isError: false });
    setIsValAllLoading(false);
  };
  // ...
  useEffect(() => {
    // if (!voeuxList.length) return setIsLoading(false);
    onValidateVoeux();
  }, [voeuxList]);
  // ...
  useEffect(() => {
    onGetProgramme();
  }, []);

  if (isLoading)
    return (
      <Container variant="v976">
        <div className="DashEnseignements">
          <div className="DashEnseignements__header">
            <div>
              <h2></h2>
              <p></p>
            </div>
            <div className="DashEnseignements__header__action" />
          </div>

          <div className="DashEnseignementsError">
            <CircularProgress className="__icon" />

            <div className="__content">
              <p>Veuillez patienter nous chargeons vos données</p>
            </div>
          </div>
        </div>
      </Container>
    );

  // ...
  return (
    <Container variant="v976">
      <div className="DashEnseignements">
        <div className="DashEnseignements__header">
          <div>
            <h2>CURSUS: {data?.cursus?.libelle || ""}</h2>
            <p>{data?.programme?.libelle || ""}</p>
          </div>
          <div className="DashEnseignements__header__action">
            {ensList?.length > 0 && (
              <Button
                width="260px"
                onClick={onValidateAll}
                disabled={!canValidateAll || !ensListVal.length}
                isLoading={isValAllLoading}>
                Valider et confimer mes choix
              </Button>
            )}
          </div>
        </div>

        <div className="DashEnseignements__content">
          {ensList.map((data, idx) => {
            return <EnsItem key={idx} data={data} status={ensListVal[idx]} />;
          })}
        </div>
      </div>
    </Container>
  );
};

export default Enseignements;
