import "./style.scss";

import React from "react";
import Moment from "react-moment";
import cx from "classnames";

import NavLink from "../NavLink";

const EnsItem = ({ data, status }) => {
  const iENS = data?.ens || {};
  const iId = iENS?.id;
  const iLabel = iENS?.libelle;
  const optsL = iENS?.options?.length || 0;
  // ...
  let optsLabel = "No options";
  if (optsL === 1) optsLabel = "1 option";
  if (optsL > 1) optsLabel = `${optsL} options`;
  // ...
  const startD = data?.date_ouverture_inscritption;
  const endD = data?.date_fermeture_inscritption;
  const hasDateRange = startD && endD;
  // ...
  return (
    <div className={cx("EnsItem", { __error: !status })}>
      <NavLink to={`/dashboard/enseignements/${iId}`}>
        <div className="EnsItem__inner">
          <div className="__col-1">
            <div className="__name">{iLabel}</div>
            <div className="__sub">{optsLabel}</div>
          </div>
          <div className="__col-2">{!status && "Aucun voeux exprimé"}</div>
          {hasDateRange ? (
            <div className="__col-3">
              <span>
                <Moment format="MMM D YYYY">{startD}</Moment>
              </span>
              <span> - </span>
              <span>
                <Moment format="MMM D YYYY">{endD}</Moment>
              </span>
            </div>
          ) : (
            <div className="__col-3" />
          )}
        </div>
      </NavLink>
    </div>
  );
};

export default EnsItem;
