/* eslint-disable react/display-name */
import "./style.scss";

import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import cx from "classnames";
import { SnackbarContent, useSnackbar } from "notistack";
import React, { forwardRef } from "react";

const AppSnackbar = forwardRef((props, ref) => {
  const { closeSnackbar } = useSnackbar();
  const { id, message } = props;
  const msg = message.msg;
  const isError = message.isError;
  const isPersist = message.isPersist;
  // ...
  return (
    <SnackbarContent
      ref={ref}
      className={cx("AppSnackbar", { __error: isError })}
    >
      {isPersist && (
        <div className="AppSnackbar__close" onClick={() => closeSnackbar(id)}>
          <CloseIcon sx={{ color: "white", width: "30px", height: "30px" }} />
        </div>
      )}

      <div className="AppSnackbar__content">
        <div className="AppSnackbar__icon">
          {isError && <ErrorIcon />}
          {!isError && <InfoIcon />}
        </div>
        <div className="AppSnackbar__msg">{msg}</div>
      </div>
    </SnackbarContent>
  );
});

export default AppSnackbar;
