import React from "react";

import MainLayout from "../../../layout/DashboardLayout";

const MainDashboard = ({ Paper, noSidebar, stepIndex, user, ...props }) => {
  return (
    <MainLayout currTab={stepIndex} noSidebar={noSidebar} user={user}>
      <Paper {...props} />
    </MainLayout>
  );
};

export default MainDashboard;
