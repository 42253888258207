import "./style.scss";

import CloseIcon from "@mui/icons-material/Close";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import cx from "classnames";
import React, { useEffect } from "react";

import Button from "../Button";
import Container from "../Container";
import NavLink from "../NavLink";

const Navbar = ({ children, window }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
    target: window ? window() : undefined
  });
  // ...
  return <div className={cx("ManageBox__navbar", { __elevated: trigger })}>{children}</div>;
};

const ManageBox = ({
  canClear,
  canNext,
  // ...
  isLoading,
  actions,
  // ...
  title,
  children
}) => {
  // ...
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // ...
  return (
    <div className="ManageBox">
      <Navbar>
        <Container variant="v1440">
          <div className="ManageBox__navbar__inner">
            <div className="ManageBox__navbar__left">
              <NavLink to={actions.close}>
                <CloseIcon style={{ width: "30px", height: "30px" }} />
              </NavLink>
            </div>

            <div className="ManageBox__navbar__between">
              <div className="__title">{title}</div>
            </div>

            <div className="ManageBox__navbar__right">
              <Button variant="__transparent" onClick={actions.onClear} disabled={!canClear || isLoading}>
              Réinitialiser
              </Button>
              <Button onClick={actions.onSave} disabled={!canNext} isLoading={isLoading}>
              Valider
              </Button>
            </div>
          </div>
        </Container>
      </Navbar>

      <Container variant="v976">
        <div className="ManageBox__content">
          <div className="ManageBox__forms">{children}</div>
        </div>
      </Container>
    </div>
  );
};

export default ManageBox;
