import React from "react";
import { Navigate } from "react-router-dom";

const None = ({ user }) => {
  // ...
  return (
    <>
      {user && <Navigate to="/dashboard/enseignements" replace={true} />}
      {!user && <Navigate to="/authentication" replace={true} />}
    </>
  );
};

export default None;
